import CheckItem, { CheckListItemProps } from "./CheckItem";

const CheckItemList = (props: {
  items: CheckListItemProps[];
  gap?: string;
  color?: string;
}) => {
  return (
    <div className={`flex flex-col ${props.gap ? props.gap : "gap-3"}`}>
      {props.items &&
        props.items.map((item, index) => (
          <CheckItem
            key={index}
            textLabel={item.textLabel}
            variant={item.variant}
            tickSize={item.tickSize}
            color={props.color}
          />
        ))}
    </div>
  );
};

export default CheckItemList;
