import { Helmet } from "react-helmet-async";

import Header, { HeaderProps } from "../header/Header";

export const HealthKeyPageFullScreen = ({
  meta: { title: pageTitle, description },
  headerProps,
  children,
}: {
  meta: { title?: string; description?: string };
  headerProps: HeaderProps | null;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex-1 w-full">
      <Helmet>
        <title>{pageTitle ? `${pageTitle} | ` : ""} HealthKey</title>
        {description && <meta name="description" content={description}></meta>}
      </Helmet>
      {headerProps !== null && <Header {...headerProps} />}
      {/* The padding-top makes room for the fixed position AppBar, we don't need this when we don't show the header -- LH, 2024-02-23 */}
      <div
        className={`flex flex-col w-full sm:flex-row ${headerProps !== null ? "py-20" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};
