import { Typography } from "@mui/material";
import React from "react";

import { CardTagProps } from "../../tags/CardTag";

const LargeWhiteChip = ({ tag }: CardTagProps) => {
  return (
    <div className="flex items-center justify-center rounded-[48px] bg-white py-3 px-6 shadow-main w-fit">
      <Typography variant="titleS" align="center">
        {tag}
      </Typography>
    </div>
  );
};

export default LargeWhiteChip;
