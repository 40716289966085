import "@mui/material";

import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tag: true;

    tagLoose: true;

    titleS: true;

    titleXXL: true;

    titleXXXL: true;

    titleXL: true;

    titleL: true;

    titleM: true;

    titleXS: true;

    bodyL: true;
    bodyBold: true;
    bodyS: true;

    body: true;

    bodyJakarta: true;
    buttonLabelLarge: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  tag: React.CSSProperties;

  tagLoose: React.CSSProperties;

  titleS: React.CSSProperties;

  titleXXL: React.CSSProperties;

  titleXXXL: React.CSSProperties;

  titleXL: React.CSSProperties;

  titleL: React.CSSProperties;

  titleM: React.CSSProperties;

  titleXS: React.CSSProperties;

  bodyBold: React.CSSProperties;
  bodyL: React.CSSProperties;
  bodyS: React.CSSProperties;

  body: React.CSSProperties;

  bodyJakarta: React.CSSProperties;
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
    xxxl: 1800,
  },
};

const theme = createTheme({
  breakpoints,
});

export const healthKeyV2Theme = createTheme({
  breakpoints,
  palette: {
    primary: {
      main: "#E35349",
      contrastText: "#fff",
    },
    secondary: {
      main: "#105A9A",
      contrastText: "#fff",
    },
    action: {
      disabledBackground: "#CED4DA",
      disabled: "#6C757D",
    },
    success: {
      main: "#097D5A",
    },
    error: {
      main: "#BD0300",
    },
  },
  typography: {
    fontFamily: [
      "Karla",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      color: "#000",
    },
    h2: {
      color: "#000",
    },
    h3: {
      color: "#000",
    },
    h4: {
      fontWeight: "600",
      fontSize: "2rem",
    },
    h5: {
      color: "#343A40",
      fontWeight: "600",
      fontSize: "1.5rem",
    },
    h6: {
      color: "#343A40",
      fontSize: "1.25rem",
      fontWeight: "600",
    },
    tag: {
      fontSize: "0.875rem",
      fontWeight: "800",
      textTransform: "uppercase",
      lineHeight: "120%",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      letterSpacing: "0.02625rem",
      color: "#2C2C2C",
    },
    tagLoose: {
      fontSize: "0.75rem",
      fontWeight: "800",
      textTransform: "uppercase",
      lineHeight: "120%",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      letterSpacing: "0.075rem",
      color: "#2C2C2C",
    },
    titleXXXL: {
      fontSize: "2.5rem",
      fontWeight: "700",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "120%",
      color: "#2C2C2C",
      letterSpacing: "-0.15rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "2.9375rem",
        lineHeight: "125%",
        letterSpacing: "-0.17625rem",
      },
    },
    titleXXL: {
      fontSize: "2.125rem",
      fontWeight: "700",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "133%",
      color: "#2C2C2C",
      letterSpacing: "-0.06375rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "2.5rem",
        letterSpacing: "-0.1rem",
      },
    },
    titleXL: {
      fontSize: "1.6875rem",
      fontWeight: "700",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "120%",
      color: "#2C2C2C",
      letterSpacing: "-0.05063rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "2.0625rem",
        letterSpacing: "-0.0825rem",
      },
    },
    titleL: {
      fontSize: "1.5625rem",
      fontWeight: "700",
      lineHeight: "142%",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      color: "#2C2C2C",
      letterSpacing: "-0.04688rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.75rem",
        lineHeight: "130%",
        letterSpacing: "-0.0525rem",
      },
    },
    titleM: {
      fontSize: "1.375rem",
      fontWeight: "700",
      lineHeight: "140%",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      color: "#2C2C2C",
      letterSpacing: "-0.66px",
    },
    titleS: {
      fontSize: "1.1875rem",
      fontWeight: "700",
      color: "#2C2C2C",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "125%",
      letterSpacing: "-0.38px",
    },
    titleXS: {
      fontSize: "1.063rem",
      fontWeight: "700",
      color: "#212529",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "150%",
      letterSpacing: "-0.34px",
    },
    bodyBold: {
      color: "#2C2C2C",
      fontSize: "1.125rem",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      fontWeight: "700",
      lineHeight: "150%",
      letterSpacing: "-0.0225rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
        letterSpacing: "-0.04rem",
      },
    },
    body2: {
      color: "#495057",
      fontSize: "0.8125rem",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      fontWeight: "500",
    },
    bodyL: {
      fontSize: "1.125rem",
      fontWeight: "400",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "140%",
      color: "#2C2C2C",
      letterSpacing: "-0.36px",
    },
    bodyS: {
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "150%",
      color: "#2C2C2C",
      letterSpacing: "-0.02rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "0.9375rem",
        letterSpacing: "-0.01875rem",
      },
    },
    body: {
      fontSize: "1.125rem",
      fontWeight: "500",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "150%",
      color: "#2C2C2C",
      letterSpacing: "-0.0225rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
    bodyJakarta: {
      fontSize: "1rem",
      fontWeight: "800",
      fontFamily: [
        "Plus Jakarta Sans",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "125%",
      color: "#2C2C2C",
      letterSpacing: "20%",
    },
    buttonLabelLarge: {
      fontSize: "1.1875rem",
      fontWeight: "700",
      fontFamily: [
        "Karla",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
      lineHeight: "125%",
      color: "#2C2C2C",
      letterSpacing: "-0.02375rem",
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "& .MuiTouchRipple-root": {
            display: "none",
          },
          boxShadow: "none",
          [theme.breakpoints.up("md")]: {
            maxWidth: "none",
            width: "fit-content",
          },
          width: "100%",
          "&:focus": {
            outline: "2px solid #005ba1",
            outlineOffset: "2px",
          },
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            textTransform: "unset",
            padding: "8px 16px",
            borderRadius: "8px",
            fontWeight: "700",
            color: "#105A9A",
            backgroundColor: "#ffffff",

            border: "2px solid #E7F1FB",
            fontFamily: [
              "Karla",
              "Roboto",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
            fontSize: "1.1875rem",
            lineHeight: "125%",
            letterSpacing: "-0.38px",
            "&:hover": {
              border: "2px solid #105A9A",
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 0px 4px rgba(37, 131, 212, 0.25)",
            },
            "&:active": {
              backgroundColor: "#E7F1FB",
            },
            ".MuiButton-endIcon": {
              marginLeft: "4px",
              marginRight: "-2px",
            },
            "&:has(.MuiButton-endIcon)": {
              paddingLeft: "16px",
              paddingRight: "14px",
            },
          },
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            textTransform: "unset",
            padding: "8px 8px",
            borderRadius: "8px",
            fontWeight: "700",
            color: "#105A9A",
            backgroundColor: "#ffffff",
            border: "2px solid #E7F1FB",
            fontFamily: [
              "Karla",
              "Roboto",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
            fontSize: "1rem",
            lineHeight: "125%",
            letterSpacing: "-0.02375rem",
            "&:hover": {
              border: "2px solid #105A9A",
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 0px 4px rgba(37, 131, 212, 0.25)",
            },
            "&:active": {
              backgroundColor: "#E7F1FB",
            },
            ".MuiButton-endIcon": {
              marginLeft: "4px",
              marginRight: "-2px",
            },
            "&:has(.MuiButton-endIcon)": {
              paddingLeft: "12px",
              paddingRight: "8px",
            },
            [theme.breakpoints.up("md")]: {
              padding: "3px 12px",
            },
          },
        },
        {
          props: { variant: "contained" },
          style: {
            textTransform: "unset",
            padding: "8px 16px",
            borderRadius: "8px",
            fontWeight: "700",
            backgroundColor: "#E35349",
            color: "#ffffff",
            border: "2px solid #D83E1D",
            fontFamily: [
              "Karla",
              "Roboto",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
            fontSize: "1.1875rem",
            lineHeight: "125%",
            letterSpacing: "-0.38px",
            "&:hover": {
              border: "2px solid #A60101",
              backgroundColor: "#E35349",
              boxShadow: "0px 0px 0px 4px rgba(227, 83, 73, 0.25)",
            },
            "&:active": {
              backgroundColor: "#D83E1D",
            },
            "&.Mui-disabled": {
              backgroundColor: "#EDEDED",
              color: "#5F5F5F",
              border: "2px solid #EDEDED",
            },
            ".MuiButton-endIcon": {
              marginLeft: "4px",
              marginRight: "-2px",
            },
            "&:has(.MuiButton-endIcon)": {
              paddingLeft: "16px",
              paddingRight: "12px",
            },
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "body" },
          style: {
            color: "#105A9A",
            fontWeight: "700",
            cursor: "pointer",
            textDecorationColor: "inherit",
          },
        },
        {
          props: { variant: "titleM" },
          style: {
            color: "#105A9A",
            cursor: "pointer",
            textDecorationColor: "inherit",
            fontFamily: "Karla",
            fontSize: "1.1875rem",
            fontWeight: "600",
            lineHeight: "120%",
            letterSpacing: "0",
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          tag: "span",
          tagLoose: "span",
          titleXXXL: "h1",
          titleXXL: "h2",
          titleXL: "h3",
          titleL: "h4",
          titleM: "h5",
          titleS: "h6",
          titleXS: "h6",
          bodyBold: "span",
          bodyL: "span",
          bodyS: "span",
          body: "span",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "#495057",
          }, // if you also want to change the color of the input, this is the prop you'd use
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: " 0px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "8px 12px",
        },
        root: {
          borderRadius: "8px",
          overflow: "hidden",
          transition: "all 0.25s ease-out",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
            border: "1px solid #8D8D8D",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `2px solid #2583D4`,
            },
          },
          "&.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid #BD0300`,
            },
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `2px solid #2583D4`,
            },
            boxShadow: "0px 0px 0px 4px rgba(37, 131, 212, 0.25)",
          },
          "&::placeholder": {
            color: "#5F5F5F",
            opacity: 1,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: `#BD0300`,
            fontSize: "0.938rem",
            fontWeight: "500",
            fontFamily: [
              "Karla",
              "Roboto",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
            lineHeight: "150%",
            letterSpacing: "-0.3px",
            margin: "4px 0px 0px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "0px 9px",
          },
        },
      },
    },
    MuiTab: {
      // defaultProps: {
      //     // The props to change the default for.
      //     disableRipple: true, // No more ripple, on the whole application 💣!
      // },
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: "600",
          lineHeight: "120%",
          flexDirection: "row-reverse",
          fontFamily: [
            "Karla",
            "Roboto",
            "Helvetica Neue",
            "Arial",
            "sans-serif",
          ].join(","),
          color: "#373737",
          letterSpacing: "0",
          borderBottom: "solid 2px #CEE0FD",
          padding: "12px 12px 8px",
          "@media (min-width: 1280px)": {
            padding: "16px 24px 6px",
          },
          textTransform: "unset",
          "&.Mui-selected": {
            color: "#105A9A",
          },
          "&:hover": {
            color: "#105A9A",
          },
          "&.Mui-focusVisible": {
            border: "2px solid #005BA1",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
          "& .MuiTab-iconWrapper": {
            marginBottom: "0px",
            marginLeft: "4px",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          zIndex: "1300",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "&.MuiDrawer-root .MuiDrawer-paper": {
            top: "60px",
            boxShadow:
              "0px 13.725px 27.45px 0px rgba(2, 0, 97, 0.05), 0px 0px 27.45px 0px rgba(2, 0, 97, 0.03)",
            position: "absolute",
            [theme.breakpoints.down("md")]: {
              height: "100%",
            },
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: "72px",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          paddingTop: "12px",
          paddingBottom: "14px",
          paddingX: "12px",
          "& .MuiBottomNavigationAction-label.Mui-selected": {
            fontSize: "16px",
            color: "#D83E1D",
          },
          "& .MuiBottomNavigationAction-label": {
            fontSize: "16px",
            color: "#373737",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "150%" /* 24px */,
            letterSpacing: "-0.32px",
            textAlign: "center",
            textWrap: "nowrap",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          borderRadius: "10px",
          height: "auto",
          "& .MuiChip-label": {
            display: "block",
            whiteSpace: "normal",
            padding: "5px 11px 5px 11px",
          },
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            textTransform: "unset",
            borderRadius: "16px",
            fontWeight: "600",
            fontFamily: [
              "Karla",
              "Roboto",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            textTransform: "unset",
            borderRadius: "16px",
            border: "1px solid #C9DEED",
            color: "#6C757D",
            fontWeight: "600",
            fontFamily: [
              "Karla",
              "Roboto",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
            fontSize: "1rem",
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            fontFamily: [
              "Karla",
              "Roboto",
              "Helvetica Neue",
              "Arial",
              "sans-serif",
            ].join(","),
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& MuiMenu-paper": {
            boxShadow:
              "0px 13.725px 27.45px 0px rgba(2, 0, 97, 0.05), 0px 0px 27.45px 0px rgba(2, 0, 97, 0.03)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#F3F9FF",
          },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "12px",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: "2px solid #E7F1FB",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: "1px solid #CEE0FD",
          padding: "12px 8px",
        },
        body: {
          borderBottom: "1px solid #CEE0FD",
          padding: "6px 8px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.3)",
        },
      },
    },
  },
});
