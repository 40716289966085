import {
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CheckItemList from "../../components/design-system/check-item-list/CheckItemList";
import LargeWhiteChip from "../../components/design-system/chips/decorative/LargeWhiteChip";
import { HealthKeyContainer } from "../../components/page-template/HealthKeyContainer";
import { HealthKeyPageFullScreen } from "../../components/page-template/HealthKeyPageFullScreen";
import { PageMargins } from "../../components/page-template/PageMargins";
import chasBanner from "../../images/chas-banner.png";
import chasHeroImageLg from "../../images/chas-hero-lg.png";
import chasHeroImageXs from "../../images/chas-hero-xs.png";
import emedLogo from "../../images/emed-logo.svg";
import fiitLogo from "../../images/fiit-logo.svg";
import introducingHealthkeyLg from "../../images/introducing-healthkey-lg.jpg";
import introducingHealthkeyXs from "../../images/introducing-healthkey-xs.jpg";
import jaqqLogo from "../../images/jaaq-logo.svg";
import marketplaceLg from "../../images/marketplace-lg.jpg";
import marketplaceXs from "../../images/marketplace-xs.jpg";
import phioLogo from "../../images/phio-logo.svg";
import { ReactComponent as LogoSVG } from "./../../images/Logo_with_text.svg";

interface Highlight {
  bold: string;
  text: string;
}

const ServiceCell = ({
  image,
  title,
  text,
  highlights,
}: {
  image: string;
  title: string;
  text: string;
  highlights: Highlight[];
}) => {
  return (
    <div className="flex flex-col pt-16 gap-6 max-w-[570px]">
      <img
        className="h-[64px] w-[114px] shadow-large rounded-lg"
        src={image}
        alt={`A Logo`}
      />
      <Typography variant="titleL">{title}</Typography>
      <Typography variant="bodyL">{text}</Typography>
      <div className="flex flex-col border-blue-2 border-[2px] bg-white rounded-lg">
        {highlights.map((highlight, index) => (
          <div
            key={index}
            className={`px-4 py-4 lg:py-2 flex flex-row items-center ${index === 0 ? "" : "border-t-blue-2 border-t-[1px]"}`}
          >
            <Typography variant="body">
              <b>{highlight.bold}</b>
              {highlight.text}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
const InviteChas = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();
  const isBiggerThanLgBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));
  const isBiggerThanXLBreakpoint = useMediaQuery(theme.breakpoints.up("xl"));

  const checkListItems = [
    {
      textLabel: t(
        "invite-from-parent-company.veriforce-chas.benefits.benefit-1",
      ),
    },
    {
      textLabel: t(
        "invite-from-parent-company.veriforce-chas.benefits.benefit-2",
      ),
    },
    {
      textLabel: t(
        "invite-from-parent-company.veriforce-chas.benefits.benefit-3",
      ),
    },
    {
      textLabel: t(
        "invite-from-parent-company.veriforce-chas.benefits.benefit-4",
      ),
    },
  ];

  return (
    <HealthKeyContainer>
      <HealthKeyPageFullScreen
        headerProps={null}
        meta={{ title: "Registration" }}
      >
        <div className={`flex flex-col md:flex-row w-full min-h-screen`}>
          <div
            role="main"
            className="flex-1 px-3 md:pl-10 xl:pr-[88px] xl:pl-[106px] 3xl:pt-[88px] 3xl:pl-[210px] w-full xl:w-[640px] 3xl:w-[864px] flex flex-col items-center"
          >
            <div
              role="banner"
              className="flex flex-row py-6 lg:justify-center w-full"
            >
              <Link href="/">
                <LogoSVG style={{ height: "24px", width: "100%" }} />
              </Link>
            </div>
            <div
              className={`xl:px-10 pb-10 flex flex-col-reverse lg:flex-row w-full bg-gradient-to-b from-white to-[200px]`}
            >
              <div className={`pt-10 lg:pt-6 flex flex-col lg:min-w-[600px]`}>
                <div className="flex flex-col gap-4 pb-4">
                  <LargeWhiteChip tag={"CHAS exclusive"} />
                  <Typography variant="titleXXXL">
                    Give your staff access to four essential health services
                  </Typography>
                  <div>
                    <CheckItemList items={checkListItems} gap={"1"} />
                  </div>
                  <div className="flex flex-row">
                    <div className="bg-blue-4 px-4 py-2 rounded-tl-lg rounded-bl-lg">
                      <Typography variant="titleL" color="white">
                        £41.67 + VAT
                      </Typography>
                    </div>
                    <div className="bg-blue-1 px-4 py-2 rounded-tr-lg rounded-br-lg flex items-center">
                      <Typography variant="bodyL" color="#105A9A">
                        <b>per user / per year</b>
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="px-6 pt-4 pb-6 bg-white border-[1px] border-blue-2 shadow-lg gap-3 flex flex-col rounded-lg">
                  <div className="flex flex-col gap-1">
                    <Typography variant="titleM">
                      Want to find out more?
                    </Typography>
                    <Typography variant="body">
                      Our lovely customer service team is always available for a
                      chat
                    </Typography>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
                    <Link
                      target="_blank"
                      href={
                        process.env.REACT_APP_EMPLOYER_ONBOARDING_CONTACT_US
                      }
                    >
                      <Button variant="contained">
                        {t(
                          "invite-from-parent-company.veriforce-chas.book-call",
                        )}
                      </Button>
                    </Link>
                    <div className="flex flex-row gap-2 items-center">
                      <Typography variant="body">or email</Typography>
                      <Link
                        href={"mailto:info@healhtkey.health"}
                        variant="body"
                      >
                        info@healthkey.health
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt:8 lg:pt-20">
                {isBiggerThanLgBreakpoint ? (
                  <img src={chasHeroImageLg} />
                ) : (
                  <img src={chasHeroImageXs} />
                )}
              </div>
            </div>
            <div className="relative w-[100vw]">
              <div id="free-section" className={`flex w-full bg-blue-1`}>
                <PageMargins>
                  <div
                    className={`flex flex-col-reverse lg:flex-row-reverse w-full gap-10 py-10 lg:px-10 lg:py-20 justify-center items-center`}
                  >
                    <div className="flex flex-col gap-8 justify-center items-center flex-1">
                      <div className="flex flex-col gap-2">
                        <Typography variant="titleXXL">
                          Introducing HealthKey
                        </Typography>
                        <Typography variant="bodyL">
                          <Trans
                            i18nKey={"chas.first-blob-text"}
                            components={{
                              b: <b />,
                              br: <br />,
                              touch: (
                                // Anchor is given content by the Trans component
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a
                                  className="underline py-2.5 text-[#105A9A] text-center text-sans font-bold leading-[150%] underline cursor-pointer"
                                  style={{
                                    color: "#105A9A",
                                    fontWeight: "700",
                                  }}
                                  href="mailto:info@healthkey.health"
                                  rel="noreferrer"
                                  target="_blank"
                                />
                              ),
                            }}
                          />
                        </Typography>
                        <Link
                          target="_blank"
                          href={`${process.env.REACT_APP_VERIFORCE_EMPLOYER_ID}`}
                          sx={{ paddingTop: "16px" }}
                        >
                          <Button variant="outlined">
                            {t("chas.free-account-button")}
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className="min-w-auto max-w-[690px] lg:min-w-[544px]">
                      <img
                        src={
                          isBiggerThanLgBreakpoint
                            ? introducingHealthkeyLg
                            : introducingHealthkeyXs
                        }
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </PageMargins>
              </div>
            </div>
            <div className="w-full py-10 lg:py-20 flex flex-col items-center">
              <Typography variant="titleXL">{t("chas.unlock")}</Typography>
              <div className="w-full flex flex-col lg:flex-row justify-between">
                <ServiceCell
                  image={emedLogo}
                  title={"eMed: Virtual GP, specialists, and prescriptions"}
                  text={
                    "eMed provides a full spectrum of digital-first healthcare services, making high-quality, personalised care accessible anytime, anywhere. This service includes virtual consultations with:"
                  }
                  highlights={[
                    {
                      bold: "General practitioners (GPs)",
                      text: " for comprehensive medical advice and management of acute and chronic conditions.",
                    },
                    {
                      bold: "Advanced nurse practitioners (ANPs)",
                      text: " who provide expert care, prescribe medications, and offer health education.",
                    },
                    {
                      bold: "Prescribing pharmacists",
                      text: " for medication management, consultations, and prescription renewals.",
                    },
                    {
                      bold: "Physiotherapists",
                      text: " to support recovery from injuries, improve mobility, and manage physical pain.",
                    },
                    {
                      bold: "Mental health practitioners",
                      text: " who provide emotional and mental health support for conditions like anxiety, depression, and stress.",
                    },
                  ]}
                />
                <ServiceCell
                  image={phioLogo}
                  title={
                    "Phio: On-demand physiotherapy for musculoskeletal health"
                  }
                  text={
                    "Phio offers an innovative solution for employees dealing with joint, bone, or muscle pain. Developed by physiotherapists, Phio enables employees to access expert musculoskeletal care quickly and easily, with self-assessment tools and tailored exercise plans."
                  }
                  highlights={[
                    {
                      bold: "Expert assessment and care pathways:",
                      text: " Phio’s online assessment tool identifies the best care pathway based on each user’s specific needs, reviewed by a physiotherapist within 24 hours. Where needed, up to 3 in-person physiotherapy sessions per year are available to each user.",
                    },
                    {
                      bold: "Phio Engage for supported self-care:",
                      text: " Through the Phio Engage app, employees receive customised exercise programs, live chat support, and progress tracking—all designed to promote recovery and sustained physical health.",
                    },
                  ]}
                />
              </div>
              <div className="w-full flex flex-col lg:flex-row justify-between">
                <ServiceCell
                  image={jaqqLogo}
                  title={
                    "JAAQ: Mental health guidance from experts and lived-experience voices"
                  }
                  text={
                    "JAAQ provides employees with valuable mental health insights from professionals and individuals with lived experience. Designed to help users understand and manage mental health concerns, JAAQ covers a wide range of issues, including anxiety, depression, ADHD, and PTSD."
                  }
                  highlights={[
                    {
                      bold: "Preventative mental health support:",
                      text: "With access to trusted advice and practical strategies, JAAQ takes a preventative approach to mental health, giving employees the tools to manage mental health challenges before they escalate.",
                    },
                    {
                      bold: "Accessible mental health education:",
                      text: " JAAQ makes mental health support accessible and inclusive, allowing users to explore essential knowledge on mental wellness for themselves or to support loved ones.",
                    },
                  ]}
                />
                <ServiceCell
                  image={fiitLogo}
                  title={"Fiit: On-demand workouts and fitness plans"}
                  text={
                    "Fiit, the UK and Ireland’s leading connected fitness platform, brings on-demand workouts to any location. Employees can choose from over 1,500 workouts and 30 tailored training plans, covering bodyweight HIIT, dumbbell strength, yoga, and Pilates."
                  }
                  highlights={[
                    {
                      bold: "Flexible, high-quality fitness options:",
                      text: " Whether employees prefer to work out at home or at the gym, Fiit offers personalised fitness solutions that cater to all levels and preferences.",
                    },
                    {
                      bold: "Interactive training experience:",
                      text: " With the option to connect with various devices and fitness equipment, Fiit enables users to track and improve their performance while staying engaged.",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="pb-10 md:pb-20 flex flex-row flex-wrap w-full">
              <div className="px-10 py-8 xl:px-12 bg-blue-1 rounded-tl-lg rounded-bl-lg flex flex-col md:flex-row flex-1 items-center">
                <div className="flex flex-col">
                  <Typography sx={{ marginBottom: "8px" }} variant="titleXL">
                    Get in touch to find out more
                  </Typography>
                  <Typography variant="bodyL">
                    Or create your free account to explore our platform
                  </Typography>
                  <div className="mt-6 flex flex-col md:flex-row gap-6 md:items-center">
                    <Link
                      target="_blank"
                      href={
                        process.env.REACT_APP_EMPLOYER_ONBOARDING_CONTACT_US
                      }
                    >
                      <Button variant="contained">Book a call</Button>
                    </Link>
                    <Link
                      target="_blank"
                      href={`${process.env.REACT_APP_VERIFORCE_EMPLOYER_ID}`}
                    >
                      <Button variant="outlined">
                        {t("chas.free-account-button")}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              {isBiggerThanLgBreakpoint && (
                <div className="bg-blue-1 flex-1 min-w-[400px] flex flex-col items-end justify-end rounded-tr-lg rounded-br-lg">
                  <img className="h-[204px]" src={chasBanner} />
                </div>
              )}
            </div>
            <div className="relative w-[100vw] pb-20">
              <div id="free-section" className={`flex w-full`}>
                <PageMargins>
                  <div
                    className={`flex flex-col-reverse lg:flex-row w-full gap-10 py-10 lg:px-10 lg:py-20 justify-center items-center`}
                  >
                    <div className="flex flex-col gap-8 justify-center items-center flex-1">
                      <div className="flex flex-col gap-2">
                        <Typography variant="titleXXL">
                          A health and wellbeing marketplace
                        </Typography>
                        <Typography variant="bodyL">
                          <Trans
                            i18nKey={"chas.second-blob-text"}
                            components={{
                              br: <br />,
                            }}
                          />
                        </Typography>
                        <Link
                          target="_blank"
                          variant="body"
                          href={`${process.env.REACT_APP_CHAS_EMPLOYER_GUIDE}`}
                          sx={{ paddingTop: "16px" }}
                        >
                          Find out more about HealthKey
                        </Link>
                      </div>
                    </div>
                    <div className="min-w-auto max-w-[690px] lg:min-w-[544px]">
                      <img
                        src={
                          isBiggerThanLgBreakpoint
                            ? marketplaceLg
                            : marketplaceXs
                        }
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </PageMargins>
              </div>
            </div>
          </div>
        </div>
      </HealthKeyPageFullScreen>
    </HealthKeyContainer>
  );
};

export default InviteChas;
