import { DoneRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Trans } from "react-i18next";

export type CheckListItemProps = {
  textLabel: string;

  variant?: "body" | "bodyL";

  tickSize?: string;
  color?: string;
};

const CheckItem = ({
  textLabel,
  variant,
  tickSize,
  color,
}: CheckListItemProps) => {
  return (
    <div className="flex flex-row gap-2 w-full items-center">
      <DoneRounded
        sx={{
          color: color ?? "#056753",
          width: tickSize ? tickSize : "32px",
          height: tickSize ? tickSize : "32px",
        }}
      />
      <Typography
        variant={variant ? variant : "bodyL"}
        sx={{
          textAlign: "start",
        }}
      >
        <Trans
          i18nKey={textLabel}
          components={{
            b: <b />,
          }}
        />
      </Typography>
    </div>
  );
};

export default CheckItem;
