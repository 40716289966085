import { isRejectedWithValue } from "@reduxjs/toolkit";
import { Middleware, MiddlewareAPI } from "redux";

import { router } from "../../App";
import {
  buildSessionExpiredUrl,
  getWindowLocationPathname,
} from "../../services/utils-service";
import authSlice from "../slices/auth";
import store, { RootState } from "../store";

export const authErrorHandler: Middleware<{}, RootState> =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (
        (action.payload as any)?.status === 401 ||
        (action.payload as any)?.status === 403
      ) {
        store.dispatch(authSlice.actions.logout());
        if (getWindowLocationPathname() !== "/createEmployer") {
          let searchParams = new URLSearchParams(document.location.search);

          router.navigate(
            buildSessionExpiredUrl(
              searchParams.get("response_type"),
              searchParams.get("client_id"),
              searchParams.get("redirect_uri"),
              searchParams.get("scope"),
              searchParams.get("code_challenge"),
              searchParams.get("code_challenge_method"),
              searchParams.get("nonce"),
              searchParams.get("state"),
              searchParams.get("claims"),
            ),
          );
        } else {
          router.navigate("/login?create_employer=true");
        }
      }
    }
    return next(action);
  };
