import { useMediaQuery, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import dayjs, { ManipulateType } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import getUserLocale from "get-user-locale";
import { t } from "i18next";
import { toast } from "react-toastify";

import authSlice from "../store/slices/auth";
import { HealthCategory, Product } from "../types/types";

dayjs.extend(localizedFormat);

const currenciesSymbolsMap: Record<string, string> = {
  GBP: "£",
  USD: "$",
  AUD: "A$",
};

const countriesVATMap: Record<string, number> = {
  GBP: 20,
  USD: 0,
  AUD: 10,
};

export const handleErrorResponse = (
  err: AxiosError,
  dispatch: any,
  navigate: any,
  sessionExpiredUrl: string,
) => {
  if (
    err.response &&
    err.response.status &&
    (err.response!.status === 401 || err.response!.status === 403)
  ) {
    dispatch(authSlice.actions.logout());
    navigate(sessionExpiredUrl);
  }
};

export const buildSessionExpiredUrl = (
  response_type: string | null,
  client_id: string | null,
  redirect_uri: string | null,
  scope: string | null,
  code_challenge: string | null,
  code_challenge_method: string | null,
  nonce: string | null,
  state: string | null,
  claims: string | null,
) => {
  let path = "/login";
  if (response_type) {
    path = path + "?response_type=" + response_type + "&";
    path = path + "client_id=" + client_id + "&";
    path = path + "redirect_uri=" + redirect_uri + "&";
    path = path + "scope=" + scope + "&";
    path = path + "code_challenge=" + code_challenge + "&";
    path = path + "code_challenge_method=" + code_challenge_method + "&";
    path = path + "nonce=" + nonce + "&";
    path = path + "state=" + state + "&";
    path = path + "claims=" + claims + "&";
  } else {
    if (!toast.isActive("session-expired")) {
      toast.info(t("session-expired.toast"), {
        toastId: "session-expired",
      });
    }
  }

  return path;
};

export const buildSessionExpiredUrl2 = (
  response_type: string | null,
  client_id: string | null,
  redirect_uri: string | null,
  scope: string | null,
  code_challenge: string | null,
  code_challenge_method: string | null,
  nonce: string | null,
  state: string | null,
  claims: string | null,
) => {
  let path = "/sessionExpired";
  if (response_type) {
    path = path + "?response_type=" + response_type + "&";
    path = path + "client_id=" + client_id + "&";
    path = path + "redirect_uri=" + redirect_uri + "&";
    path = path + "scope=" + scope + "&";
    path = path + "code_challenge=" + code_challenge + "&";
    path = path + "code_challenge_method=" + code_challenge_method + "&";
    path = path + "nonce=" + nonce + "&";
    path = path + "state=" + state + "&";
    path = path + "claims=" + claims + "&";
  }
  return path;
};

export const getHealthCategoryById = (
  id: string | undefined,
  healthCategories: HealthCategory[],
): HealthCategory | undefined => {
  if (id) {
    for (let category of healthCategories) {
      if (category.id === id) {
        return category;
      }
    }
  }
  return undefined;
};

export const getHealthCategoryByHtmlId = (
  id: string | undefined,
  healthCategories: HealthCategory[],
): HealthCategory | undefined => {
  if (id) {
    for (let category of healthCategories) {
      if (category.htmlId === id) {
        return category;
      }
    }
  }
  return undefined;
};
export const getFriendlyParsedDate = (date) => {
  return dayjs(date).format("LL");
};

export const getParsedDate = (date) => {
  const dateDate = new Date(date);
  return dateDate.toLocaleString(getUserLocaleHK());
};

export const getParsedDateNoTime = (date) => {
  const dateDate = new Date(date);
  return dateDate.toLocaleDateString(getUserLocaleHK());
};

export const isServiceInTheList = (
  serviceId: string,
  servicesList: Array<Product>,
): boolean => {
  let exists = false;
  for (let service of servicesList) {
    if (serviceId === service.id) {
      exists = true;
    }
  }
  return exists;
};

export const objToStrMap = (obj: { [x: string]: any }) => {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
};

export const getWindowLocationPathname = () => {
  return window.location.pathname;
};

export const formatNumberAsCurrency = (
  value: number,
  numberFormatOptions: Intl.NumberFormatOptions & {
    trailingZeroDisplay?: "stripIfInteger";
  } = {},
): string => {
  return (value + 0).toLocaleString(getUserLocaleHK(), {
    style: "currency",
    currency: getCurrencyShortForm(),
    ...numberFormatOptions,
  }); // Avoid this formatting as negative zero by adding zero https://stackoverflow.com/a/77037516 -- LH, 2024-06-10
};

export const truncate = (str: string, maxLength: number = 5) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + "...";
  }
};

export const getNowDateNoTimeAsString = (
  value?: number | undefined,
  unit?: ManipulateType | undefined,
) => {
  if (value && unit) {
    return dayjs()
      .add(value, unit)
      .toDate()

      .toLocaleDateString(getUserLocaleHK());
  }
  return dayjs().toDate().toLocaleDateString(getUserLocaleHK());
};

export const generateFileNameWithDate = (n: string): string => {
  return n + "-" + dayjs().format("YYYY-MM-DD");
};

export const useDefaultHeaderHeight = (): number => {
  const theme = useTheme();
  const isBiggerThanLgBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));
  return !isBiggerThanLgBreakpoint ? 72 : 80;
};

export const determineTimeUnitString = (
  number: number,
  unit: string,
): string => {
  if (number === 1) {
    return unit.substring(0, unit.length - 1);
  }
  return unit;
};

export const getCurrencySymbol = (): string => {
  return process.env.REACT_APP_CURRENCY
    ? currenciesSymbolsMap[process.env.REACT_APP_CURRENCY]
    : "£";
};

export const getCurrencyShortForm = (): string => {
  return process.env.REACT_APP_CURRENCY ?? "GBP";
};
const getUserLocaleHK = () => {
  return getUserLocale({ fallbackLocale: "en-GB" });
};

export const getVATValue = (): number => {
  return process.env.REACT_APP_CURRENCY
    ? countriesVATMap[process.env.REACT_APP_CURRENCY]
    : 20;
};
